import React from "react";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

const TimelineComp = ({workData}) => {
  return (
    <Timeline lineColor={"#07AAF5"}>
        {workData?.map((data)=>( 
      <TimelineItem
        key="001"
        dateText=""
        dateInnerStyle={{ background: "#07AAF5" }}
      >
        <h3>{data.mainHeading}</h3>
        <p>
          {data.content}
        </p>
      </TimelineItem>
        ))}
    </Timeline>
  );
};

export default TimelineComp;
