import React, { useEffect, useState, useContext } from "react";
import styles from "../home-template/AboutPageTemplate.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Editor } from "primereact/editor";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link, useNavigate, useParams } from "react-router-dom";
import { showErrorToast } from "../../../Toster";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/Loader/LoaderContext";
import TopNavBar from "../../backend/layouts/TopNavBar";

const AddHomePage = () => {
  const navigate = useNavigate();
  const { authPermissions } = useContext(AuthContext);
  const aboutTemplateValidation = Yup.object({
    pageName: Yup.string().required("Page Name is Required"),
    slug: Yup.string().required("Slug is Required"),
    mainImage: Yup.mixed().required("Banner Image is Required"),
  });

  const [submitButton, setSubmitButton] = useState("Submit");
  const [image, setImage] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);
  const [pageName, setPagename] = useState("Add Home Template");
  const { id } = useParams();
  const coreImages = [
    "BsFillCalculatorFill",
    "TbTarget",
    "FaDiceD6",
    "FaHeart",
  ];
  const initialValues = {
    AboutUs: {
      mainHeading: "",
      subHeading1: "",
      subHeading2: "",
      subHeading3: "",
      subHeading4: "",
      DoctorImage: "",
      DoctorImage2: "",
      card: [
        {
          content: "",
        },
        {
          content2: "",
        },
      ],
      bannerDocument: "",
    },
    personalinfo: {
      card: [
        {
          heading: "",
          content: "",
        },
      ],
    },
    language: {
      card: [
        {
          heading: "",
          content: "",
        },
      ],
    },

    professional: {
      card: [
        {
          heading: "",
          content: "",
        },
      ],
    },
    professional2: {
      card: [
        {
          heading: "",
          content: "",
        },
      ],
    },
    // styled: {
    //     card: [
    //         {
    //             heading: "",
    //         },

    //     ],
    // },
    // hobby: {
    //     card: [
    //         {
    //             heading: "",
    //         },

    //     ],
    // },
    publication: {
      card: [
        {
          heading: "",
          content: "",
        },
      ],
    },
    workexp: {
      card: [
        {
          mainHeading: "",
          content: "",
        },
      ],
    },
    education: {
      card: [
        {
          mainHeading: "",
          heading: "",
          content: "",
        },
      ],
    },
    refrences: {
      card: [
        {
          content: "",
        },
      ],
    },
    gallery: {
      card: [
        {
          images: "",
        },
      ],
    },
  };
  // const initialValues = {
  //     pageName: "",
  //     slug: "",
  //     bannerValues: {
  //         mainHeading: "",
  //         bannerImage: undefined,
  //         boxes: [
  //             {
  //                 id: 1,
  //                 heading: "",
  //                 image: "",
  //                 backgroundColor: "box-1",
  //                 Url: "",
  //             },
  //             {
  //                 id: 2,
  //                 heading: "",
  //                 image: "",
  //                 backgroundColor: "box-2",
  //                 Url: "",
  //             },
  //             {
  //                 id: 3,
  //                 heading: "",
  //                 image: "",
  //                 backgroundColor: "box-3",
  //                 Url: "",
  //             },
  //             {
  //                 id: 4,
  //                 heading: "",
  //                 image: "",
  //                 backgroundColor: "box-4",
  //                 Url: "",
  //             },
  //         ],
  //     },

  //     plantGrowthValues: {
  //         mainHeading: "",
  //         leafImage: "./assets/images/home/leaf-1-1 1.webp",
  //         mainContent: "test",

  //         plantInformation: [
  //             {
  //                 content1: {
  //                     subHeading: "",
  //                     subContent: "",
  //                 },

  //                 image: "",

  //                 content2: {
  //                     subHeading: "",
  //                     subContent: "",
  //                 },
  //             },

  //             {
  //                 image: "",
  //                 content: {
  //                     subHeading: "",
  //                     subContent: "",
  //                 },
  //                 image1: "",
  //             },

  //             {
  //                 content1: {
  //                     subHeading: "",
  //                     subContent: "",
  //                 },

  //                 image: "",

  //                 content2: {
  //                     subHeading: "",
  //                     subContent: "",
  //                 },
  //             },
  //         ],
  //     },

  //     blogValues: {
  //         mainHeading: "",
  //         mainContent: "",
  //         viewMoreBlogBtn: "View More Blogs",
  //         posts: [
  //             {
  //                 heading: "",
  //                 content: "",
  //                 readMore: "",
  //                 image: "",
  //                 writerName: "",
  //                 writerImage: "",
  //             },
  //         ],
  //     },

  //     coreValues: {
  //         backgroundImage: "",
  //         leafImage: "./assets/images/home/leaf-1-1 1.webp",
  //         mainHeading: "",

  //         card: [
  //             {
  //                 heading: "",
  //                 content: "",
  //                 mainHeading: "",
  //             },
  //             // {
  //             //   heading: "YIELD QUANTITY",
  //             //   content:
  //             //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //             //   icon: "TbTarget",
  //             // },
  //             // {
  //             //   heading: "PELLET FORM",
  //             //   content:
  //             //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //             //   icon: "FaDiceD6",
  //             // },
  //             // {
  //             //   heading: "ECO-FRIENDLY",
  //             //   content:
  //             //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //             //   icon: "FaHeart",
  //             // },
  //         ],
  //     },
  // };

  function setTextData(e) {
    console.log(e.htmlValue);
    setFieldValue(values.plantGrowthValues.mainContent, e.htmlValue);
  }

  // page name onBlur method
  const onPageNameBlur = (event) => {
    const { name, value } = event.target;
    if (name === "pageName") {
      let slug = value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
      setFieldValue("slug", slug);
    } else {
      handleBlur(event);
    }
  };

  //  Image upload method
  const onFileUpload = (event) => {
    console.log(event.target.files[0], event.target.name);
    let formData = new FormData();
    formData.append("image", event.target.files[0]);
    setLoaderCheck(true);
    axiosPrivate
      .post("pages/file-upload", formData)
      .then((res) => {
        setLoaderCheck(false);
        console.log(res, event.target.name);
        setFieldValue(event.target.name, res.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        setFieldValue(event.target.name, null);
      });
    handleChange(event);
  };

  // add row in first section
  const onAddRow = (name, index, check) => {
    if (name === "blog_value") {
      if (check) {
        let newObj = {
          heading: "",
          content: "",
          readMore: "Read more",
          image: "./assets/images/home/Rectangle 29.webp",
          writerName: "",
          writerImage: "",
        };
        let rowsData = values.blogValues.posts;
        if (rowsData?.length < 3) {
          rowsData.push(newObj);
          setFieldValue(`posts`, rowsData);
        }
      } else {
        let rowsData = values.blogValues.posts;
        if (rowsData?.length > 1) {
          rowsData?.splice(index, 1);
          setFieldValue("posts", rowsData);
        }
      }
    }
    if (name === "core_value") {
      let rowsData = values.coreValues.card;
      if (check) {
        let newObj = {
          heading: "",
          content: "",
          mainHeading: "",
        };
        if (rowsData?.length < 4) {
          rowsData.splice(index + 1, 0, newObj); // Insert newObj at the specified index
          setFieldValue("card", rowsData);
        }
      } else {
        let rowsData = values.coreValues.card;
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("card", rowsData);
        }
      }
    }
    if (name === "personalinfo") {
      let rowsData = values.personalinfo.card;
      if (check) {
        let newObj = {
          heading: "",
          content: "",
        };
        if (rowsData?.length < 7) {
          rowsData.push(newObj);
          setFieldValue("personalinfo.card", rowsData);
        }
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("personalinfo.card", rowsData);
        }
      }
    }
    if (name === "language") {
      let rowsData = values.language.card;
      if (check) {
        let newObj = {
          heading: "",
          content: "",
        };
        if (rowsData?.length < 7) {
          rowsData.push(newObj);
          setFieldValue("language.card", rowsData);
        }
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("language.card", rowsData);
        }
      }
    }
    if (name === "professional") {
      let rowsData = values.professional.card;
      if (check) {
        let newObj = {
          heading: "",
          content: "",
        };
        if (rowsData?.length < 7) {
          rowsData.push(newObj);
          setFieldValue("professional.card", rowsData);
        }
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("professional.card", rowsData);
        }
      }
    }
    if (name === "professional2") {
      let rowsData = values.professional2.card;
      if (check) {
        let newObj = {
          heading: "",
          content: "",
        };
        if (rowsData?.length < 7) {
          rowsData.push(newObj);
          setFieldValue("professional2.card", rowsData);
        }
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("professional2.card", rowsData);
        }
      }
    }
    // if (name === "styled") {
    //     let rowsData = values.styled.card;
    //     if (check) {
    //         let newObj = {
    //             heading: "",
    //         };
    //         rowsData.push(newObj);
    //         setFieldValue("styled.card", rowsData);
    //     } else {
    //         if (rowsData?.length > 1) {
    //             rowsData.splice(index, 1);
    //             setFieldValue("styled.card", rowsData);
    //         }
    //     }
    // }
    // if (name === "hobby") {
    //     let rowsData = values.hobby.card;
    //     if (check) {
    //         let newObj = {
    //             heading: "",
    //         };
    //         rowsData.push(newObj);
    //         setFieldValue("hobby.card", rowsData);
    //     } else {
    //         if (rowsData?.length > 1) {
    //             rowsData.splice(index, 1);
    //             setFieldValue("hobby.card", rowsData);
    //         }
    //     }
    // }
    if (name === "publication") {
      let rowsData = values.publication.card;
      if (check) {
        let newObj = {
          heading: "",
          content: "",
        };
        rowsData.push(newObj);
        setFieldValue("publication.card", rowsData);
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("publication.card", rowsData);
        }
      }
    }
    if (name === "workexp") {
      let rowsData = values.workexp.card;
      if (check) {
        let newObj = {
          mainHeading: "",
          content: "",
        };
        rowsData.push(newObj);
        setFieldValue("workexp.card", rowsData);
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("workexp.card", rowsData);
        }
      }
    }
    if (name === "education") {
      let rowsData = values.education.card;
      if (check) {
        let newObj = {
          mainHeading: "",
          heading: "",
          content: "",
        };
        rowsData.push(newObj);
        setFieldValue("education.card", rowsData);
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("education.card", rowsData);
        }
      }
    }
    if (name === "refrences") {
      let rowsData = values.refrences.card;
      if (check) {
        let newObj = {
          content: "",
        };
        rowsData.push(newObj);
        setFieldValue("refrences.card", rowsData);
      } else {
        if (rowsData?.length > 1) {
          rowsData.splice(index, 1);
          setFieldValue("refrences.card", rowsData);
        }
      }
    }
    if (name === "gallery") {
        let rowsData = values.gallery.card;
        if (check) {
          let newObj = {
            images: "",
          };
          rowsData.push(newObj);
          setFieldValue("gallery.card", rowsData);
        } else {
          if (rowsData?.length > 1) {
            rowsData.splice(index, 1);
            setFieldValue("gallery.card", rowsData);
          }
        }
      }
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: aboutTemplateValidation,
    onSubmit: (values, { setFieldError }) => {
      let formData = {
        name: values.pageName,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: "home",
      };

      console.log(formData);

      if (id) {
        setLoaderCheck(true);
        axiosPrivate
          .put(`pages/update/${id}`, formData)
          .then((response) => {
            setLoaderCheck(false);
            navigate("/admin/home-template");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (error.response) {
              showErrorToast("Internal server error");

              if (error.response.status == 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      } else {
        setLoaderCheck(true);
        axiosPrivate
          .post("pages/create", formData)
          .then((response) => {
            setLoaderCheck(false);
            navigate("/admin/home-template");
          })
          .catch((error) => {
            setLoaderCheck(false);
            if (error.response) {
              showErrorToast("Internal server error");

              if (error.response.status == 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      setLoaderCheck(true);
      axiosPrivate
        .get("pages/" + id)
        .then((res) => {
          setLoaderCheck(false);
          const parsedValues = JSON.parse(res.data?.data?.page_json);
          Object.keys(parsedValues).forEach((key) => {
            setFieldValue(key, parsedValues[key]);
          });

          setSubmitButton("Update");
          setPagename("Edit Home Template");
        })

        .catch((error) => {
          setLoaderCheck(false);
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
        });
    }
  }, []);

  return (
    <div>
      <TopNavBar
        links={{
          list: "/admin/home-template",
          add: "/admin/home-template/add",
        }}
      />
      <div className={styles.templateContainer}>
        <h1 className={styles.mainHeading}>{pageName}</h1>
        <form onSubmit={handleSubmit} className={styles.aboutForm}>
          <div className={styles.mainFormContainer}>
            <div className="row">
              {/* Page name field */}
              <div className=" my-2 col-12 col-lg-4">
                <label
                  htmlFor="pagename"
                  className={` ${styles.fieldName} form-label`}
                >
                  Page name<span className={styles.red}>*</span>
                </label>
                <input
                  name="pageName"
                  id="pageName"
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  value={values.pageName}
                  onChange={handleChange}
                  onBlur={onPageNameBlur}
                />
                {touched.pageName && errors.pageName ? (
                  <div className={styles.red}>{errors.pageName}</div>
                ) : null}
              </div>

              {/* slug field */}
              <div className=" my-2 col-12 col-lg-4">
                <label
                  htmlFor="slug"
                  className={` ${styles.fieldName} form-label`}
                >
                  Slug<span className={styles.red}>*</span>
                </label>
                <input
                  name="slug"
                  id="slug"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.slug}
                />
                {touched.slug && errors.slug ? (
                  <div className={styles.red}>{errors.slug}</div>
                ) : null}
              </div>
            </div>
          </div>
          <h6>About Us Section</h6>
          <div className={styles.mainFormContainer}>
            <div className="row">
              <div className="col-12 col-lg-4">
                <label className={` ${styles.fieldName} form-label`}>
                  Main Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name="AboutUs.mainHeading"
                  id="AboutUs.mainHeading"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.AboutUs.mainHeading}
                />
              </div>
              <div className="col-12 col-lg-4">
                <label className={` ${styles.fieldName} form-label`}>
                  Sub Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name="AboutUs.subHeading1"
                  id="AboutUs.subHeading1"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.AboutUs.subHeading1}
                />
              </div>
              <div className="col-12 col-lg-4">
                <label className={` ${styles.fieldName} form-label`}>
                  Sub Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name="AboutUs.subHeading2"
                  id="AboutUs.subHeading2"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.AboutUs.subHeading2}
                />
              </div>
              <div className="col-12 col-lg-4">
                <label className={` ${styles.fieldName} form-label`}>
                  Sub Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name="AboutUs.subHeading3"
                  id="AboutUs.subHeading3"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.AboutUs.subHeading3}
                />
              </div>
              <div className="col-12 col-lg-4">
                <label className={` ${styles.fieldName} form-label`}>
                  Sub Heading<span className={styles.red}>*</span>
                </label>
                <input
                  name="AboutUs.subHeading4"
                  id="AboutUs.subHeading4"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.AboutUs.subHeading4}
                />
              </div>
              <div className="col-12 col-lg-4">
                <label className={` ${styles.fieldName} form-label`}>
                  Doctor Image<span className={styles.red}>*</span>
                </label>
                <input
                  name="AboutUs.DoctorImage"
                  id="AboutUs.DoctorImage"
                  type="file"
                  className="form-control"
                  onChange={onFileUpload}
                  onBlur={handleBlur}
                />
                {typeof values.AboutUs.DoctorImage == "string" &&
                values.AboutUs.DoctorImage != "" ? (
                  <img
                    style={{ width: "100px", height: "100px" }}
                    className="mt-2"
                    src={values.AboutUs.DoctorImage}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 col-lg-4">
                <label className={` ${styles.fieldName} form-label`}>
                  Doctor Image 2<span className={styles.red}>*</span>
                </label>
                <input
                  name="AboutUs.DoctorImage2"
                  id="AboutUs.DoctorImage2"
                  type="file"
                  className="form-control"
                  onChange={onFileUpload}
                  onBlur={handleBlur}
                />
                {typeof values.AboutUs.DoctorImage2 == "string" &&
                values.AboutUs.DoctorImage2 != "" ? (
                  <img
                    style={{ width: "100px", height: "100px" }}
                    className="mt-2"
                    src={values.AboutUs.DoctorImage2}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            {values.AboutUs.card.map((res, index) => (
              <div className="col-6 col-lg-5 mt-2">
                <label className={` ${styles.fieldName} form-label`}>
                  About Doctor<span className={styles.red}>*</span>
                </label>
                <textarea
                  name={`AboutUs.card[${index}].content`}
                  id={`AboutUs.card[${index}].content`}
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.AboutUs.card[index].content}
                ></textarea>
              </div>
            ))}
            <div className="col-12 col-lg-4">
              <label className={` ${styles.fieldName} form-label`}>
                Upload CV<span className={styles.red}>*</span>
              </label>
              <input
                name="AboutUs.bannerDocument"
                id="AboutUs.bannerDocument"
                type="file"
                accept=".pdf,.doc,.docx"
                className="form-control"
                onChange={onFileUpload}
                onBlur={handleBlur}
              />
              {typeof values.AboutUs.Document === "string" &&
              values.AboutUs.Document !== "" ? (
                <div className="mt-2">
                  <a
                    href={values.AboutUs.Document}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                </div>
              ) : (
                ""
              )}
              {values.AboutUs.card.map((res, index) => (
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    About Doctor 2<span className={styles.red}>*</span>
                  </label>
                  <textarea
                    name={`AboutUs.card[${index}].content2`}
                    id={`AboutUs.card[${index}].content2`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.AboutUs.card[index].content2}
                  ></textarea>
                </div>
              ))}
            </div>
          </div>
          <h5>Personal Information</h5>
          <div className={styles.mainFormContainer}>
            {values.personalinfo.card.map((res, index) => (
              <>
                <div className="row my-2">
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      Heading<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`personalinfo.card[${index}].heading`}
                      id={`personalinfo.card[${index}].heading`}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.personalinfo.card[index].heading}
                    />
                  </div>
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      Content<span className={styles.red}>*</span>
                    </label>
                    <textarea
                      name={`personalinfo.card[${index}].content`}
                      id={`personalinfo.card[${index}].content`}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.personalinfo.card[index].content}
                    ></textarea>
                  </div>

                  <div
                    className={`${styles.addDataButton_home} col-12 col-lg-1`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("personalinfo", index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("personalinfo", index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
          <h5>LANGUAGE</h5>
          <div className={styles.mainFormContainer}>
            {values.language.card.map((res, index) => (
              <>
                <div className="row my-2">
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      Language Name<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`language.card[${index}].heading`}
                      id={`language.card[${index}].heading`}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.language.card[index].heading}
                    />
                  </div>
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      level<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`language.card[${index}].content`}
                      id={`language.card[${index}].content`}
                      type="number"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.language.card[index].content}
                    ></input>
                  </div>

                  <div
                    className={`${styles.addDataButton_home} col-12 col-lg-1`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("language", index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("language", index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
          <h5>Professional Skills</h5>
          <div className={styles.mainFormContainer}>
            {values.professional.card.map((res, index) => (
              <>
                <div className="row my-2">
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      Skills<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`professional.card[${index}].heading`}
                      id={`professional.card[${index}].heading`}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.professional.card[index].heading}
                    />
                  </div>
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      level<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`professional.card[${index}].content`}
                      id={`professional.card[${index}].content`}
                      type="number"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.professional.card[index].content}
                    ></input>
                  </div>

                  <div
                    className={`${styles.addDataButton_home} col-12 col-lg-1`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("professional", index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("professional", index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
          <h5>Professional Skills</h5>
          <div className={styles.mainFormContainer}>
            {values.professional2.card.map((res, index) => (
              <>
                <div className="row my-2">
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      Skills<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`professional2.card[${index}].heading`}
                      id={`professional2.card[${index}].heading`}
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.professional2.card[index].heading}
                    />
                  </div>
                  <div className="col-6 col-lg-5 mt-2">
                    <label className={` ${styles.fieldName} form-label`}>
                      level<span className={styles.red}>*</span>
                    </label>
                    <input
                      name={`professional2.card[${index}].content`}
                      id={`professional2.card[${index}].content`}
                      type="number"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.professional2.card[index].content}
                    ></input>
                  </div>

                  <div
                    className={`${styles.addDataButton_home} col-12 col-lg-1`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("professional2", index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("professional2", index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
          {/* <h5>Styled list</h5>
                    <div className={styles.mainFormContainer}>

                        {values.styled.card.map((res, index) => (
                            <>
                                <div className="row my-2">
                                    <div className="col-6 col-lg-5 mt-2">
                                        <label className={` ${styles.fieldName} form-label`}>
                                            Skills<span className={styles.red}>*</span>
                                        </label>
                                        <input
                                            name={`styled.card[${index}].heading`}
                                            id={`styled.card[${index}].heading`}
                                            type="text"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.styled.card[index].heading}
                                        />
                                    </div>

                                    <div
                                        className={`${styles.addDataButton_home} col-12 col-lg-1`}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => {
                                                onAddRow("styled", index, true);
                                            }}
                                        >
                                            +
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                onAddRow("styled", index, false);
                                            }}
                                        >
                                            -
                                        </button>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>

                    <h5>Hobby list</h5>
                    <div className={styles.mainFormContainer}>

                        {values.hobby.card.map((res, index) => (
                            <>
                                <div className="row my-2">
                                    <div className="col-6 col-lg-5 mt-2">
                                        <label className={` ${styles.fieldName} form-label`}>
                                            Skills<span className={styles.red}>*</span>
                                        </label>
                                        <input
                                            name={`hobby.card[${index}].heading`}
                                            id={`hobby.card[${index}].heading`}
                                            type="text"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.hobby.card[index].heading}
                                        />
                                    </div>

                                    <div
                                        className={`${styles.addDataButton_home} col-12 col-lg-1`}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => {
                                                onAddRow("hobby", index, true);
                                            }}
                                        >
                                            +
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                onAddRow("hobby", index, false);
                                            }}
                                        >
                                            -
                                        </button>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div> */}
          <h5>TEACHING EXPERIENCE</h5>
          <div className={styles.mainFormContainer}>
            {values.publication.card.map((res, index) => (
              <div className="row my-2" key={index}>
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    TITLE<span className={styles.red}>*</span>
                  </label>
                  <input
                    name={`publication.card[${index}].heading`}
                    id={`publication.card[${index}].heading`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.publication.card[index].heading}
                  />
                </div>
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    About TEACHING<span className={styles.red}>*</span>
                  </label>
                  <textarea
                    name={`publication.card[${index}].content`}
                    id={`publication.card[${index}].content`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.publication.card[index].content}
                  ></textarea>
                </div>

                <div className={`${styles.addDataButton_home} col-12 col-lg-1`}>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("publication", index, true);
                    }}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("publication", index, false);
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
          <h5>Work Experience</h5>
          <div className={styles.mainFormContainer}>
            {values.workexp.card.map((res, index) => (
              <div className="row my-2" key={index}>
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    Work Title,Company<span className={styles.red}>*</span>
                  </label>
                  <input
                    name={`workexp.card[${index}].mainHeading`}
                    id={`workexp.card[${index}].mainHeading`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.workexp.card[index].mainHeading}
                  />
                </div>

                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    Experience<span className={styles.red}>*</span>
                  </label>
                  <textarea
                    name={`workexp.card[${index}].content`}
                    id={`workexp.card[${index}].content`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.workexp.card[index].content}
                  ></textarea>
                </div>

                <div className={`${styles.addDataButton_home} col-12 col-lg-1`}>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("workexp", index, true);
                    }}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("workexp", index, false);
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
          <h5>Education</h5>
          <div className={styles.mainFormContainer}>
            {values.education.card.map((res, index) => (
              <div className="row my-2" key={index}>
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    Degree<span className={styles.red}>*</span>
                  </label>
                  <input
                    name={`education.card[${index}].mainHeading`}
                    id={`education.card[${index}].mainHeading`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.education.card[index].mainHeading}
                  />
                </div>
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    Institution<span className={styles.red}>*</span>
                  </label>
                  <input
                    name={`education.card[${index}].heading`}
                    id={`education.card[${index}].heading`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.education.card[index].heading}
                  />
                </div>
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    Place<span className={styles.red}>*</span>
                  </label>
                  <textarea
                    name={`education.card[${index}].content`}
                    id={`education.card[${index}].content`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.education.card[index].content}
                  ></textarea>
                </div>

                <div className={`${styles.addDataButton_home} col-12 col-lg-1`}>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("education", index, true);
                    }}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("education", index, false);
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
          <h5>Refrences</h5>
          <div className={styles.mainFormContainer}>
            {values.refrences.card.map((res, index) => (
              <div className="row my-2" key={index}>
                <div className="col-6 col-lg-5 mt-2">
                  <label className={` ${styles.fieldName} form-label`}>
                    Comment<span className={styles.red}>*</span>
                  </label>
                  <textarea
                    name={`refrences.card[${index}].content`}
                    id={`refrences.card[${index}].content`}
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.refrences.card[index].content}
                  ></textarea>
                </div>

                <div className={`${styles.addDataButton_home} col-12 col-lg-1`}>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("refrences", index, true);
                    }}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onAddRow("refrences", index, false);
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
          <h5>Gallery</h5>
          <div className={styles.mainFormContainer}>
            {values.gallery.card.map((res, index) => (
              <>
                <div className="mb-3" key={index}>
                  <label className="form-label">
                    Upload Photos<span className={styles.red}>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id={`gallery.card[${index}].images`}
                    name={`gallery.card[${index}].images`}
                    onChange={onFileUpload}
                  />

                  {typeof values.gallery.card[index].images == "string" &&
                  values.gallery.card[index].images != "" ? (
                    <img
                      style={{ width: "100px", height: "100px" }}
                      className="mt-2"
                      src={values.gallery.card[index].images}
                    />
                  ) : (
                    ""
                  )}
                  <div
                    className={`${styles.addDataButton_home} col-12 col-lg-1`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("gallery", index, true);
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        onAddRow("gallery", index, false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              id="button_1"
              className={`${styles.closeButton} btn`}
            >
              <Link to="/admin/home-template"> Close</Link>
            </button>

            <button
              type="submit"
              id="button_2"
              className={`${styles.submitButton} btn`}
            >
              {submitButton}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddHomePage;
