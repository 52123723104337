import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbarfront from "../UI-layout/Navbarfront";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import imgblog from "../../../assets/dr.amit_info/coming-soon-3d-text-white-surface-clear-lighting_38295-20.avif";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import { showErrorToast } from "../../../Toster";

const Blogs = () => {
  const { id } = useParams();
  const [Blog, setBlog] = useState([]);
  const { setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/blogs/${id}`, options)
      .then((blog) => {
        setLoaderCheck(false);
        setBlog(blog.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          showErrorToast("Internal Server Error");
        }
      });
  }, []);
  return (
    <div style={{ alignItems: "center" }}>
      {/* <h2 style={{ color: "black" }} className="my-5">
        Blogs
      </h2>
      <div class="card mb-3">
        <img src={Blog.featured_image} class="card-img-top" alt="..."></img>
        <div class="card-body">
          <h5
            style={{ fontWeight: "bold", textAlign: "center" }}
            className="card-title"
          >
            {Blog.title}
          </h5>
          <p className="card-text">{Blog.description}</p>
        </div>
      </div> */}

      <img src={imgblog} width={"100%"}></img>
    </div>
  );
};

export default Blogs;
