import React, { useState } from "react";
import { FaBehance, FaDribbble, FaFacebookF, FaTwitter, FaYoutube, FaLinkedin } from 'react-icons/fa';
import "../UI-layout/Rightbar.css";
import Avatar from "react-avatar";
import img1 from "../../../assets/dr.amit_info/3.jpg";
import img2 from "../../../assets/dr.amit_info/2.jpg";

const Docard = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      id="mCSB_1"
      className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside"
      style={{ width:" 140%"}}
   
    // margin-right: 44%;

    >
      <div id="mCSB_1_container" className="mCSB_container">
        <div
          id="crtSidebarInner"
          className="mCustomScrollbar _mCS_1 mCS-autohide"
        >
          <div
            className="on-hoverA"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div style={{ textAlign: "center" }}>
              <Avatar
                src={isHovered ? img1 : img2}
                round={true}
                size="200"
              />
            </div>
          </div>
          <br />
          <h2 style={{ textAlign: "center" , color:"white"}}>Dr. Amit A.</h2>
          <br />
          <p className="text1" style={{ textAlign: "center", color: "white" }}>
            <div className="skills">
              <div className="skill1">
                Consultant Neurologist, MBBS MD(Gen Medicine) DM Neurology
              </div>
              <div className="skill2">
                SCE NEUROLOGY- (Royal College of Physicians, UK)
              </div>
              <div className="skill3">
                FELLOW OF EUROPEAN BOARD NEUROLOGY ( FEBN)
              </div>
              <div className="skill4">
                Fellowship in Epilepsy ( AIIMS, New Delhi)
              </div>
            </div>
          </p>
          <br />
          {/* Social media links */}
          <div className="d-flex justify-content-center ">
            <a href="https://www.linkedin.com/in/amit-arora-2b009a76" className="mx-3">
              <FaLinkedin style={{ color: "white" }} size={20} />

            </a>
            <span style={{ color: "white" }}>Linkedin</span>
          </div>
          <br />
        </div>
      </div>
    </div>
  )
}

export default Docard
