import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";
import { RiMenu5Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FaBehance, FaDribbble, FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';
import doctorLogo from "../../../assets/images/dr amit arora logo updated.png"

import Rightbar from "./Rightbar";


const Navbarfront = () => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] =useState(false);
  // const [avatarUrl, setAvatarUrl] = useState('');
  

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };


  return (
    <div>
      <nav className="topbar">
        <div className="container1">
          <a className="logo" >
          <Link className="link" to="/">
          <img src={doctorLogo} width={"30%"} alt="logo" />
          </Link>
          </a>
          <form className="d-flex" role="search">
            <ul className="nav-list">
              <li className="nav-item">
              <Link className="link" to="/">
                Home
                </Link>
              </li>
              <li className="nav-item" style={{marginRight:'6.5vw'}}>
                <Link className="link" to="/Blogs">
                Blog
                </Link>
              </li>
              {/* <li className="nav-item">
                <button
                  className="btn  nav-menu-button"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={toggleOffcanvas}
                
                >
                  <RiMenu5Fill style={{ fontSize: "1.5em" }} />
                </button>
              </li> */}
            </ul>
          </form>
        </div>
      </nav>

      {/* <div
        className={`offcanvas offcanvas-end ${isOffcanvasOpen ? "show" : ""}`}
        tabIndex="-1"
        id="offcanvasRight"
        style={{ width: "25vw" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Offcanvas right
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={toggleOffcanvas}
          ></button>
        </div>
        <div className="offcanvas-body">
        <Rightbar/>
      </div>
      </div> */}
    </div>
  );
};

export default Navbarfront;
