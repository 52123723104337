import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdEmail, MdLocationPin } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styles from './Topbar.module.css';

function Topbar() {
    return (
        <div className={styles.topbar}>
            {/* Contact */}
            <section className={styles.contactContainer}>
                <div className={styles.contactDetail}>
                    <p className={styles.tollFree}>Contact Me: <a href="tel:+971 50 205 9338"><span>+971 50 205 9338</span></a></p>
                    <Link to="/"> <BsThreeDotsVertical className={styles.setting} /></Link>
                    <a href="mailto:amitarora.neuro@gmail.com">
                        <div className={`${styles.mailContainer} ${styles.link}`}>
                            <MdEmail className={styles.mailIcon} />
                            <p className={styles.mail}>amitarora.neuro@gmail.com</p>
                        </div>
                    </a>
                </div>
            </section>

            {/* Account */}
            <section className={`${styles.accountContainer} ${styles.secondSection}`}>
                <div className={styles.social}>
                <a href="https://www.google.com/maps/place/Cleveland+Clinic+Abu+Dhabi%E2%80%8E/@24.4976378,54.3875503,489m/data=!3m1!1e3!4m16!1m9!3m8!1s0x3e5e66530654bad9:0xf40306482aae392e!2sCleveland+Clinic+Abu+Dhabi%E2%80%8E!8m2!3d24.4972965!4d54.3879995!9m1!1b1!16s%2Fm%2F0r4lp_n!3m5!1s0x3e5e66530654bad9:0xf40306482aae392e!8m2!3d24.4972965!4d54.3879995!16s%2Fm%2F0r4lp_n?entry=ttu&g_ep=EgoyMDI0MTIwOC4wIKXMDSoASAFQAw%3D%3D">
                        <div className={`${styles.mailContainer} ${styles.link}`}>
                            <MdLocationPin  />
                            <p className={styles.mail}>Cleveland Clinic,Abu Dhabi</p>
                        </div>
                        </a>
                </div>
            </section>
        </div>
        
    )
}

export default Topbar