import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../DataTable2";
import { FaGreaterThan } from 'react-icons/fa';
import axiosPrivate from "../../../../hooks/axiosPrivate";
import { GrView } from 'react-icons/gr';
import LoaderContext from "../../../../context/Loader/LoaderContext"

function DistributorEnquiry() {
	const [distributorState, setDistributorState] = useState([]);
	const { setLoaderCheck } = useContext(LoaderContext);

	const headers = [
		{ name: 'Message', field: 'message', sortable: true, classKey: '' },
		{ name: 'Status', field: 'status', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	const searchItems = ['Message', 'Status'];

	useEffect(() => {
		const options = {
			headers: { "content-type": "application/json" },
		};
		setLoaderCheck(true);
		axiosPrivate
			.get(`/reviews`, options)
			.then((res) => {
				setLoaderCheck(false);
				console.log(res?.data?.data)
				const distributordata = res.data?.data?.map((value, key) => {
					let buttons = [];

					buttons.push(
						<Link
							key={`viewButton##${key}`}
							type="button"
							to={`/admin/reviews/edit/${value.id}`}
							className="btn btn-icon"
							title="View"
						>
							<GrView style={{ color: 'blue' }} size={18} />
						</Link>
					);
                   value["message"]=value?.message?.length >40 ?value?.message.slice(0, 40):"..."
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

				setDistributorState(distributordata);
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					console.log(error.response.status);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log(error.message);
				}
			});
	}, [])

	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					<div className="card-header">
						<strong className="top-left-heading">Reviews</strong>
						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Reviews</li>
						</ol>
					</div>

					<div className="tab-content">
						<div className="tab-pane fade show active" id="enquiry-list" role="tabpanel">
							<div className="card">
								<div className="card-header">
									<h1 className="card-title" style={{ fontWeight: '700', fontSize: '18px' }}>
                                    Reviews
									</h1>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={distributorState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default DistributorEnquiry;