import React, { useState, useEffect, useContext } from 'react';
import { Avatar } from 'primereact/avatar';
import { FaUserAlt } from 'react-icons/fa';
import './Refrences.css';
import axiosPrivate from '../../../hooks/axiosPrivate';
import LoaderContext from '../../../context/Loader/LoaderContext';
import { showErrorToast, showSuccessToast } from '../../../Toster';

const AvatarWithComment = ({ comment }) => {
  const { setLoaderCheck } = useContext(LoaderContext);
  const [review, setReview] = useState([]);
  
  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/reviews`, options)
      .then((res) => {
        setLoaderCheck(false);
        console.log(res?.data?.data);
        setReview(res?.data?.data);
      })
      .catch((err) => {
        setLoaderCheck(false);
        if (err.response) {
          console.log(err.response.status);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err.message);
        }
        showErrorToast(err.message);
      });
  }, []);

  return (
    <div className="avatar-comment">
      <div className="user-info">
        {review
          .filter((res) => res.status === 'Publish')
          .map((data) => (
            <React.Fragment key={data.id}>
              <Avatar icon={<FaUserAlt />} size="large" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} />
              <p>{data?.message}</p>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default AvatarWithComment;
