import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import "./Fixnav.css"
import { Avatar } from 'primereact/avatar';
import img1 from "../../../assets/dr.amit_info/IMG_4000.JPG"
import { FaAddressCard,FaBriefcase,FaComment,FaFeather,FaLocationArrow} from "react-icons/fa";

const VerticalNavbar = () => {
  return (
    <Navbar id="vertical-navbar" bg="white" style={{width:'100%'}}>
<Nav className="flex-column">
        <Nav.Link href="#section1">
        <Avatar image={img1} shape="circle" /></Nav.Link>
        <Nav.Link className='mb-3' href="#section2"><FaAddressCard size={25}/></Nav.Link>
        <Nav.Link className='mb-3' href="#section3"><FaBriefcase size={25}/></Nav.Link>
        <Nav.Link className='mb-3' href="#section4"><FaComment size={25}/></Nav.Link>
        <Nav.Link className='mb-3' href="#section5"><FaFeather size={25}/></Nav.Link>
        <Nav.Link className='mb-3' href="#section6"><FaLocationArrow size={25}/></Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default VerticalNavbar;
