import React from "react";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

const TimelineComp = ({timelineData}) => {
  return (
    <Timeline lineColor={"#07AAF5"}>
      {timelineData?.map((data)=>(
      <TimelineItem
        key="001"
        dateText={data.heading}
        dateInnerStyle={{ background: "#07AAF5" }}
      >
        <p>
          {data.content}
        </p>
      </TimelineItem>
       ))} 
    </Timeline>
  );
};

export default TimelineComp;
