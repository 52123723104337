import React from 'react'
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';



const TimelineComp = ({educationData}) => {
    console.log(educationData);
    return (
        <Timeline lineColor={'#07AAF5'}>
            {educationData?.map((data)=>(
            <TimelineItem
                key="001"
                dateText={data.mainHeading}
                dateInnerStyle={{ background: '#07AAF5' }}
            >
                <h3>{data.heading}</h3>
                
                <p>
                {data.content}
                </p>

            </TimelineItem>
        ))}
           
        </Timeline>
    )
}

export default TimelineComp

