import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertContext from '../../../../context/Alert/AlertContext';
import DataTable2 from '../../../DataTable2';
import TopNavBar from '../../layouts/TopNavBar';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import AuthContext from '../../../../context/Auth/AuthContext';
import { showErrorToast } from "../../../../Toster";
import LoaderContext from '../../../../context/Loader/LoaderContext';

const Users = (props) => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const [userState, setUserState] = useState([]);
	const [refresh, setRefreshTable] = useState(false);

	const headers = [
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },
		{ name: 'CONTACT NUMBER', field: 'mobile', sortable: true, classKey: '' },
		{ name: 'ROLE', field: 'role_name', sortable: true, classKey: '' },
		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'email', 'mobile', 'role_name'];

	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		setLoaderCheck(true);
		axiosPrivate
			.get('users', options)
			.then((users) => {
				setLoaderCheck(false);
				const usersData = users.data.data.map((value, key) => {
					let buttons = [];
					if (authPermissions.includes('User-update'))
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/users/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<i className="fa fa-edit"></i>
							</Link>
						);
					if (true)
						buttons.push(
							<Link
								key="userButton##1"
								type="button"
								to={`/admin/users/permissions/${value.id}/${value.role_id}`}
								className="btn btn-icon"
								title="Permission"
							>
								<i className="fa fa-user"></i>
							</Link>
						);
					if (authPermissions.includes('User-delete'))
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => userDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<i className="fa fa-trash-o text-danger"></i>
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					value['mobile'] = String(value.mobile);
					return value;
				});

				if (authPermissions.includes('User-read')) {
					setUserState(usersData);
				} else {
					setUserState([]);
				}
			})
			.catch((error) => {
				showErrorToast(error.message);
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [refresh]);

	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	const userDeleteHandler = async (id) => {
		setLoaderCheck(false);
		axiosPrivate
			.delete(`users/${id}`)
			.then(async (response) => {
				setLoaderCheck(true);
				if (response.data.code === 200) {
					setUserState([]);
					setAlertMessage({ message: 'users deleted successfully' });
					setRefreshTable(!refresh);
					await refreshTable();
				}
			})
			.catch((error) => {
				showErrorToast(error.message);
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	};

	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('users', options)
				.then((users) => {
					const usersData = users.data.data.map((value, key) => {
						let buttons = [];
						if (authPermissions.includes('User-update'))
							buttons.push(
								<Link
									key="editButton##1"
									type="button"
									to={`/admin/users/edit/${value.id}`}
									className="btn btn-icon"
									title="Edit"
								>
									<i className="fa fa-edit"></i>
								</Link>
							);
						if (true)
							buttons.push(
								<Link
									key="userButton##1"
									type="button"
									to={`/admin/users/permissions/${value.id}/${value.role_id}`}
									className="btn btn-icon"
									title="Permission"
								>
									<i className="fa fa-user"></i>
								</Link>
							);
						if (authPermissions.includes('User-delete'))
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => userDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<i className="fa fa-trash-o text-danger"></i>
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});

					if (authPermissions.includes('User-read')) {
						setUserState(usersData);
					} else {
						setUserState([]);
					}
				})
				.catch((error) => {
					showErrorToast(error.message);
				})
		});
	};

	const addLink = authPermissions.includes('User-create') === true ? '/admin/users/add' : '';

	return (
		<div>
			<TopNavBar links={{ list: '/admin/users', add: addLink }} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">USER LIST</h3>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={userState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Users;
