import { Fragment, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AuthMain = (props) => {
    const navigate = useNavigate();
    const path = useLocation();
    const login = localStorage.getItem('userData');

    useEffect(() => {
        if (!login) {
            if(path.pathname != '/auth/signup'){
                navigate('/auth/signin');
            }
        } else {
            navigate('/admin'); 
        }
    }, [login, navigate]);

    return (
        <Fragment>
            <div className="font-montserrat">
                <div className="auth">
                    <Outlet />
                </div>
            </div>
            < ToastContainer />
        </Fragment>
    );
};

export default AuthMain;
