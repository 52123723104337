import { Fragment } from "react";
import SideBar from './SideBar';
import Header from './Header';
import Footer from './Footer'
import { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from '../../../context/Auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../context/Loader/Loader";

const Main = (props) => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    console.log(user);
    if (user != undefined) {
      if(Object.keys(user).length == 0){
        let token = localStorage.getItem('userData');
        if(!token){
          navigate('/auth/signin');
        }
      }
    } 

    else{
      let token = localStorage.getItem('userData');
        if(!token){
          navigate('/auth/signin');
        }
    }
    // else {
    //   const lastLocation = localStorage.getItem('lastLocation');
    //   // if (lastLocation) {
    //   //   localStorage.removeItem('lastLocation');
    //   //   navigate(lastLocation);
    //   // } else {
    //   //   navigate("/admin", { replace: true });
    //   // }
    // }
  }, []);

//   useEffect(() => {
//     function handleBeforeUnload() {
//       localStorage.setItem('lastLocation', history.pathname);
//     }
//     function getLastLocation() {
//       localStorage.setItem('lastLocation', history.pathname);
//     }
//     handleBeforeUnload();

//   }, [history]);

//   useEffect(()=>{
//     const lastLocation = localStorage.getItem('lastLocation');
//     if (lastLocation) {
//       localStorage.removeItem('lastLocation');
//       navigate(lastLocation);
//     }
//   },[history]);

//   if (!user.token) {
//     return null
//   }
  return (
    <Fragment>
      <Loader />
      <div className="font-montserrat">
        <div id="main_content">
          <div className="">
            <div>
              <SideBar />
            </div>
            <div className="page">
              <Header />
              <Outlet />
              <Footer />
            </div>
          </div>
        </div>
      </div>
      < ToastContainer />
    </Fragment>
  );
};

export default Main;
