import React from "react";
import Blogs from "./Blogs";
import Navbar from "../UI-layout/Navbarfront";
import { ScrollTop } from "primereact/scrolltop";
import "../UI-layout/MainUi.css";
import Docard from "./Docard";

const BlogUi = () => {
  return (
 <div className="d-flex">
    <div className="ml-4 mt-5">
        <div className="d-none d-lg-inline">
        <Docard  />
        </div>
       
    </div>
    <div className="m-0 main-container">
      <ScrollTop style={{ backgroundColor: "#07AAF5" }} />
      
        <div className="mainNav">
          <Navbar />
        </div>
        <div className="child-container">
            <div>
                <div  className="d-inline d-lg-none">
                <Docard />
                </div>
            
          <Blogs />
            </div>
           
          
        </div>
      </div>
      </div>
  );
};

export default BlogUi;
