import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import AuthContext from "../../../../context/Auth/AuthContext";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import TopNavBar from "../../layouts/TopNavBar";

// Validation schema
const validationSchema = Yup.object().shape({
  status: Yup.string().required("Status is required"),
});

const EditBlog = () => {
  const { id } = useParams();

  const { setAlertMessage } = useContext(AlertContext);
  const { authPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const [getData, setGetData] = useState('');
  const { setLoaderCheck } = useContext(LoaderContext);
  const [message, setMessage] = useState("");

  // Blog get by id
  useEffect(() => {
    setLoaderCheck(true);
    axiosPrivate
      .get(`reviews/${id}`) 
      .then((response) => {
        setLoaderCheck(false);
        if (response.data.code === 200) {
          formik.setFieldValue("id", response?.data?.data?.id);
          formik.setFieldValue("status", response?.data?.data?.status);
          setMessage(response?.data?.data?.message);
          setGetData(formik.values)
        }
      })
      .catch((error) => {
        setLoaderCheck(false);
      });
  }, []);

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoaderCheck(true);

      try {
        const response = await axiosPrivate.get(`/reviews/update/${id}/${getData}`);

        if (response.data.code === 200) {
          setLoaderCheck(false);
          setAlertMessage({ message: "Reviews Update Successfully" });
          navigate("/admin/reviews");
        } else {
          setLoaderCheck(false);
          showErrorToast(response.data?.message || "Update failed");
        }
      } catch (error) {
        setLoaderCheck(false);
        showErrorToast(error.response?.data?.message || "Update failed");
      }
    },
  });

  return (
    <div>
      <TopNavBar
        links={{ list: "/admin/reviews", add: "/admin/reviews/edit" }}
      />
      <div className="section-body ">
        <div className="container-fluid">
          <div className="tab-content ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  {/* Heading */}
                  <div className="card-header">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Edit Reviews
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      {/*   blog   message */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Message:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="message"
                            name="message"
                            type="text"
                            className="form-control"
                            onBlur={formik.handleBlur}
                            value={message}
                          />
                          {formik.touched.message && formik.errors.message ? (
                            <div>{formik.errors.message}</div>
                          ) : null}
                        </div>
                      </div>
                      {/*   status */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Status:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="status"
                            name="status"
                            onChange={(e)=>{
                              setGetData(e.target.value);
                              formik.handleChange(e)
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option value="Select Status">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Publish">Publish</option>
                          </select>
                        </div>
                      </div>
                      {/* horizontal line */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <hr className="form-line" />
                      </div>
                      {/* button close and submit */}
                      <div className="col-12 submit-btn  text-right">
                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/reviews")}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          id="button_2"
                          className="btn btn-primary ml-2"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBlog;
