import React, { useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "./Reviews.css";
import { showSuccessToast,showErrorToast } from "../../../Toster";

function ReviewForm() {
  const [review, setReview] = useState({
    message: "",
    status: "pending",
  });

  const handleReviewChange = (event) => {
    const { name, value } = event.target;
    setReview({ ...review, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axiosPrivate
      .post("/reviews/create", review)
      .then((res) => {
        if (res.data?.code == 201) {
          showSuccessToast("sucessfully submitted");
          setReview({ message: "", status: "pending" });
        }
      })
      .catch((err) => { 
       
        showErrorToast(err?.response?.data?.message ||err?.response?.data?.errors[0]?.msg ||err?.response?.data?.errors?.msg)});
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Write Your Review:
          <textarea
            name="message"
            id="message"
            value={review.message}
            onChange={handleReviewChange}
            rows="10"
            cols="100"
            required
          />
        </label>
        <br />
        <button className="button" type="submit">
          Submit Review
        </button>
      </form>
    </div>
  );
}

export default ReviewForm;
